import React, { ChangeEvent, useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import linkResolver from 'prismic/helpers/linkResolver';
import { getLanguageNativeNames } from 'helpers/header.helper';
import { DocumentRelationsContext } from 'contexts/documentRelations/DocumentRelationsContext';
import { DomainContext } from 'contexts/domain/DomainContext';
import { LanguageSwitcherProps } from './LanguageSwitcher.types';
import { ArrowStyled, LabelStyled, Wrapper } from './LanguageSwitcher.styles';
import { SelectContainerStyled, SelectStyled, OptionStyled } from './LanguageSwitcherMobile.styles';

export const LanguageSwitcherMobile: React.FC<LanguageSwitcherProps> = ({
  className,
  withShortText,
  withWhiteSkin,
  options,
}) => {
  const { lang } = useTranslation('common');
  const documentRelations = useContext(DocumentRelationsContext);
  const router = useRouter();
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  if (isCourse) {
    return <></>;
  }

  return (
    <Wrapper withWhiteSkin={withWhiteSkin} withShortText={withShortText} className={className}>
      <LabelStyled>
        {getLanguageNativeNames(lang, withShortText)} <ArrowStyled />
      </LabelStyled>
      <SelectContainerStyled>
        <SelectStyled
          defaultValue="#"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            router.push(event.currentTarget.value, event.currentTarget.value, {
              locale: event.currentTarget.value.split('/')[1],
            });
          }}
        >
          <option value="#">{getLanguageNativeNames(lang, withShortText)}</option>
          {options?.map((option) => (
            <OptionStyled
              key={`switch_${option.label}`}
              value={linkResolver(option.url, documentRelations) as string}
            >
              {getLanguageNativeNames(option.label, withShortText)}
            </OptionStyled>
          ))}
        </SelectStyled>
      </SelectContainerStyled>
    </Wrapper>
  );
};
