import { styled } from '@nx-kit/styling';
import Button from 'components/atoms/Button/Button';

export const ModalSearchStyled = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 360px;
  height: auto;
  top: 34px;
  right: 60px;
  box-shadow: 0 10px 15px -5px rgba(11, 11, 11, 0.6);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 10px;
  background-color: ${(props) => props.theme.global.color.white};
  z-index: ${({ theme }) => theme.global.zIndex.ModalSearch};
`;

export const FormStyled = styled.form`
  display: flex;
  align-items: center;
`;

export const ButtonSearchStyled = styled(Button)`
  height: 59px;
  margin-left: 10px;
`;
