import { styled, media } from '@nx-kit/styling';

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 60px;
  ${media('md')} {
    height: 130px;
  }
`;

export const HeaderSpacerGuide = styled.div`
  width: 100%;
  height: 110px;
  ${media('md')} {
    height: 150px;
  }
`;
