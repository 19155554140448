import { PrismicLink } from 'prismic/types';

export type LanguageOptions = { label: string; url: PrismicLink }[];

const locales = {
  'de-ch': 'de',
  'en-gb': 'en',
  'fr-ch': 'fr',
  'it-ch': 'it',
};

export const generateLanguageOptions = (languageLinks: PrismicLink[] | []): LanguageOptions => {
  if (languageLinks && languageLinks.length > 0) {
    return languageLinks.map((link) => ({
      label: locales[link.lang as keyof typeof locales],
      url: link,
    }));
  }
  return [];
};

export const getLanguageNativeNames = (langKey: string, withShortText?: boolean): string => {
  const languageNames = {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
  };

  const languageNamesShort = {
    de: 'DE',
    en: 'EN',
    fr: 'FR',
    it: 'IT',
  };

  if (withShortText) {
    return languageNamesShort[langKey as keyof typeof languageNamesShort] || 'De';
  }
  return languageNames[langKey as keyof typeof languageNames] || 'Deutsch';
};
