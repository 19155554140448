import { Data } from 'prismic/types';
import HeadWrapper from 'head/HeadWrapper';
import PrismicToolbar from 'thirdPartyScripts/PrismicToolbar';

interface TemplateWrapperProps {
  pageId: string;
  data: Data;
  children?: React.ReactNode;
}

const TemplateWrapper: React.FC<TemplateWrapperProps> = ({ pageId, data, children }) => (
  <HeadWrapper data={data}>
    <PrismicToolbar pageId={pageId} />
    {children}
  </HeadWrapper>
);

export default TemplateWrapper;
