import { css, styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';

export const TriangleStyled = styled.div<{ isOpen: boolean }>`
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  ${({ isOpen }) =>
    isOpen
      ? css`
          border-width: 0 4.5px 5px 4.5px;
          border-color: transparent transparent ${(props) => props.theme.global.color.primary}
            transparent;
        `
      : css`
          border-width: 5px 4.5px 0 4.5px;
          border-color: ${(props) => props.theme.global.color.primary} transparent transparent
            transparent;
        `};
`;

export const SelectedOptionStyled = styled.div`
  width: 100%;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${TriangleStyled} {
    margin-left: 5px;
  }
`;

export const OptionsStyled = styled.div<{
  withWhiteSkin?: boolean;
  withShortText?: boolean;
  isOpen: boolean;
}>`
  position: absolute;
  top: calc(100% + 1px); // height + border
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  box-shadow: 0 10px 15px -5px rgba(11, 11, 11, 0.6);

  ${({ withWhiteSkin, withShortText }) => {
    if (withWhiteSkin && withShortText) {
      return css`
        width: 80px;
      `;
    } else if (!withWhiteSkin && withShortText) {
      return css`
        width: 50px;
        top: calc(100% - 10px + 4px);
      `;
    } else if (!withWhiteSkin && !withShortText) {
      return css`
        width: 120px;
        top: calc(100% - 10px + 4px);
      `;
    } else {
      return css`
        width: 140px;
      `;
    }
  }}
`;

export const OptionStyled = styled.div`
  width: 100%;
  height: auto;
`;

export const LinkStyled = styled(Link)<{ withWhiteSkin?: boolean }>`
  display: block;
  font-weight: 400;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  padding: 2px 20px 2px 10px;

  ${({ withWhiteSkin }) => {
    if (withWhiteSkin) {
      return css`
        background-color: ${(props) => props.theme.global.color.white};
        color: ${(props) => props.theme.global.color.gray900};
        &:hover {
          color: ${(props) => props.theme.global.color.white};
          background-color: ${(props) => props.theme.global.color.primary};
        }
      `;
    } else {
      return css`
        color: ${(props) => props.theme.global.color.white};
        background-color: ${(props) => props.theme.global.color.gray900};
        &:hover {
          background-color: ${(props) => props.theme.global.color.primary};
        }
      `;
    }
  }}
`;
