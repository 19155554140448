import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { PagesPaths } from 'types/pages';
import { cleanRouterQuery, mapObjectToQueryString } from 'helpers/url';
import useTranslation from 'next-translate/useTranslation';
import { TextInput } from 'components/atoms/TextInput';
import { SearchFormTypes, SearchModalProps } from './SearchModal.types';
import { ModalSearchStyled, FormStyled, ButtonSearchStyled } from './SearchModal.styles';

export const SearchModal: React.FC<SearchModalProps> = ({ className, isOpen, setIsOpen }) => {
  const router = useRouter();
  const { t, lang } = useTranslation('common');

  const {
    setValue,
    register,
    formState: {},
    handleSubmit,
  } = useForm<SearchFormTypes>();

  function onLeave(): void {
    setIsOpen(false);
    setValue('key', '');
  }

  const submitHandler = async (data: SearchFormTypes) => {
    onLeave();
    const queryObj = cleanRouterQuery(router.query);
    queryObj.key = data.key;
    const queryString = mapObjectToQueryString(queryObj);
    router.push(`/${lang}/${PagesPaths.search}${queryString}`, undefined);
  };

  useEffect(() => {
    const optionsEl = document.getElementById('search-modal');
    if (optionsEl) {
      optionsEl.addEventListener('mouseleave', onLeave);
    }
    return () => {
      document.removeEventListener('mouseleave', onLeave);
    };
  }, []);

  return (
    <ModalSearchStyled isOpen={isOpen} className={className} id="search-modal">
      <FormStyled onSubmit={handleSubmit(submitHandler)}>
        <TextInput
          label=""
          type="search"
          name="key"
          placeholder={t('searchInputPlaceholder')}
          register={register}
        />
        <ButtonSearchStyled skin="primary" icon="LOUPE" type="submit" />
      </FormStyled>
    </ModalSearchStyled>
  );
};
