import Head from 'next/head';
import { useContext } from 'react';
import { DomainContext } from 'contexts/domain/DomainContext';

const Favicons = () => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  if (isCourse) {
    return (
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon-kurse.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32-kurse.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16-kurse.png" />
        <link rel="manifest" href="/favicons/site-kurse.webmanifest" />
      </Head>
    );
  }

  return (
    <Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/favicons/site.webmanifest" />
    </Head>
  );
};

export default Favicons;
