import { useRef, useState, useCallback, useEffect } from 'react';

const useIsSticky = (providedRef?: React.RefObject<any>) => {
  const stickyRef = useRef<any>(null);
  const usedRef = providedRef ?? stickyRef;
  const [isSticky, setSticky] = useState(false);

  const onScroll = useCallback(() => {
    const { current } = usedRef;

    if (current) {
      const { top } = current.getBoundingClientRect();
      setSticky(top < 0);
    }
  }, [usedRef, setSticky]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return {
    isSticky,
    isStickyProps: {
      ref: usedRef,
    },
  };
};

export default useIsSticky;
