import { ParsedUrlQuery } from 'querystring';

export interface QueryObject {
  [p: string]: string | string[] | undefined;
}

export const mapObjectToQueryString = (queryObj: QueryObject): string => {
  let queryString = '';
  if (queryObj) {
    queryString += '?';
    queryString += Object.keys(queryObj)
      .map((key) => {
        if (queryObj[key]) {
          return `${key}=${encodeURIComponent(String(queryObj[key]))}`;
        }
        return '';
      })
      .join('&');
  }
  return queryString;
};

export const mapQueryStringToObject = (queryString: string): QueryObject => {
  let queryObj: QueryObject = {};
  const s = queryString.replace('?', '');
  const sKeyValue = s.split('&');
  sKeyValue.forEach((keyValueItem) => {
    const [k, v] = keyValueItem.split('=');
    if (k && v) {
      queryObj[k] = v;
    }
  });
  return queryObj;
};

export const cleanRouterQuery = (query: ParsedUrlQuery): ParsedUrlQuery => {
  const copyQuery = { ...query };
  delete copyQuery.domain;
  delete copyQuery.lang;
  delete copyQuery.slug; //Remove the query param "slug", added on search in the mobile navigation
  return copyQuery;
};
