import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Spacer } from '@nx-kit/layout';
import { GUIDE_BASE } from 'prismic/config';
import { LanguageOptions } from 'helpers/header.helper';
import { LanguageSwitcherDesktop } from 'components/molecules/LanguageSwitcher';
import { SearchModal } from 'components/molecules/SearchModal';
import { HeaderProps } from 'components/organisms/Header';
import Link from 'components/atoms/Link/Link';
import { ButtonStyled, MetaBar } from './Meta.styles';

type MetaProps = {
  links: HeaderProps['data']['meta_links'];
  languageOptions: LanguageOptions;
};

const Meta: React.FC<MetaProps> = ({ links, languageOptions }) => {
  const { asPath } = useRouter();

  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);

  return (
    <MetaBar spacing="30px">
      {links.map(({ link, link_text }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Link skin="navMeta" url={link} key={`meta-${link.url}-${index}`}>
          {link_text}
        </Link>
      ))}
      <Spacer />
      {!asPath.includes(`/${GUIDE_BASE}`) && (
        <LanguageSwitcherDesktop withShortText options={languageOptions} />
      )}
      <ButtonStyled icon="LOUPE" onPress={() => setIsSearchModalOpen((prevState) => !prevState)} />
      <SearchModal isOpen={isSearchModalOpen} setIsOpen={setIsSearchModalOpen} />
    </MetaBar>
  );
};

export default Meta;
