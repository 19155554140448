import { styled } from '@nx-kit/styling';

export const SelectContainerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SelectStyled = styled.select`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 0 none;
  background-color: transparent;
  color: transparent;
`;

export const OptionStyled = styled.option`
  background-color: ${({ theme }) => theme.global.color.white};
  color: ${({ theme }) => theme.global.color.gray900};
  appearance: none;
`;
