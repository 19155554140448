import { DomainType } from 'prismic/config';
import { Data } from 'prismic/types';
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next';

export interface PageTemplateProps {
  data: Data;
  header: Data;
  footer: Data;
}

/*
 * GENERIC PAGES interfaces
 * */

export interface ServerSideProps extends GetServerSidePropsContext {
  locale: string;
  params: {
    domain: string;
    slug: string[];
  };
}

export interface PageProps {
  type: string;
  domain: DomainType;
  header: Data;
  footer: Data;
  data: Data;
  sharedData: any;
  documentRelations: any;
}
export type NextPageProps = NextPage<PageProps>;
export type ServerSidePropsResult = Promise<GetServerSidePropsResult<PageProps>>;

/*
 * GUIDE PAGES interfaces
 * */

export interface ServerSideGuideProps extends GetServerSidePropsContext {
  locale: string;
  params: {
    domain: string;
    segments: string[];
  };
}

export interface GuidePageProps extends PageProps {
  guideData: {
    house?: string;
    isGuide: boolean;
  };
}
export type NextGuidePageProps = NextPage<GuidePageProps>;
export type ServerSideGuidePropsResult = Promise<GetServerSidePropsResult<GuidePageProps>>;

/*
 * GUIDE HOUSE interfaces
 * */

export interface ServerSideGuideHouseProps extends GetServerSidePropsContext {
  locale: string;
  params: {
    domain: string;
    house: string;
  };
}

/*
 * Paths
 * */

export enum PagesPaths {
  search = 'search',
}
