import { styled } from '@nx-kit/styling';
import { HStack } from '@nx-kit/layout';
import Icon from 'components/atoms/Icon/Icon';
import Button from 'components/atoms/Button/Button';

export const MetaBar = styled(HStack)`
  background-color: ${({ theme }) => theme.global.color.gray900};
  color: ${({ theme }) => theme.global.color.white};
  padding: 4px 60px;
  height: 34px;
  position: fixed;
  width: 100%;
  z-index: ${(props) => props.theme.global.zIndex.metaBar};
`;

export const IconMapStyled = styled(Icon)`
  color: ${(props) => props.theme.global.color.white};
  width: 18px;
  height: 18px;
  margin-top: 5px;
`;

export const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.global.color.white};
  width: 18px;
  height: 18px;
`;
