import { css, styled } from '@nx-kit/styling';

export const Wrapper = styled.div<{ withWhiteSkin?: boolean; withShortText?: boolean }>`
  position: relative;

  ${({ withWhiteSkin, withShortText, theme }) =>
    withWhiteSkin
      ? css`
          border-radius: 8px;
          border: 1px solid ${theme.global.color.gray500};
          height: 46px;

          min-width: ${withShortText ? '80px' : '140px'};
          background-color: ${theme.global.color.white};
        `
      : css`
          user-select: none;
          font-size: 13px;
          line-height: 20px;
        `}
`;

export const ArrowStyled = styled.i<{ isOpen?: boolean }>`
  pointer-events: none;
  &:after {
    content: '';
    will-change: transform;
    transition: transform 300ms ${({ theme }) => theme.global.easing.default};
    position: absolute;
    transform-origin: center;
    right: 20px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-bottom: 2px solid ${({ theme }) => theme.global.color.primary500};
    border-right: 2px solid ${({ theme }) => theme.global.color.primary500};
    transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, 45deg);

    ${({ isOpen }) =>
      isOpen
        ? css`
            top: calc(50% - 2px);
            transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, -135deg);
          `
        : css`
            top: calc(50% - 7px);
            transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, 45deg);
          `};
  }
`;

export const LabelStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  height: 100%;
  cursor: pointer;
  user-select: none;

  ${ArrowStyled} {
    margin-left: 30px;
  }
`;
