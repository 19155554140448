import React, { useContext, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getLanguageNativeNames } from 'helpers/header.helper';
import { DomainContext } from 'contexts/domain/DomainContext';
import { LanguageSwitcherProps } from './LanguageSwitcher.types';
import { LabelStyled, ArrowStyled, Wrapper } from './LanguageSwitcher.styles';
import {
  SelectedOptionStyled,
  LinkStyled,
  OptionsStyled,
  OptionStyled,
  TriangleStyled,
} from './LanguageSwitcherDesktop.styles';

export const LanguageSwitcherDesktop: React.FC<LanguageSwitcherProps> = ({
  className,
  options,
  withWhiteSkin,
  withShortText,
}) => {
  const { lang } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function switchOpen() {
    setIsOpen((prevState) => !prevState);
  }

  function onClickOutsideEvent(event: { target: any }): void {
    if (!event.target.getAttribute('data-dropdown-id')) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutsideEvent);
    const optionsEl = document.getElementById('language-options');
    if (optionsEl) {
      optionsEl.addEventListener('mouseleave', () => {
        setIsOpen(false);
      });
    }
    return () => {
      document.removeEventListener('click', onClickOutsideEvent);
    };
  }, []);

  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  if (isCourse) {
    return <></>;
  }

  return (
    <Wrapper
      withShortText={withShortText}
      withWhiteSkin={withWhiteSkin}
      className={className}
      id="language-options"
    >
      {withWhiteSkin ? (
        <LabelStyled onClick={switchOpen} data-dropdown-id="language-dropdown">
          {getLanguageNativeNames(lang, withShortText)} <ArrowStyled isOpen={isOpen} />
        </LabelStyled>
      ) : (
        <SelectedOptionStyled onClick={switchOpen} data-dropdown-id="language-dropdown">
          {getLanguageNativeNames(lang, withShortText)} <TriangleStyled isOpen={isOpen} />
        </SelectedOptionStyled>
      )}

      <OptionsStyled withShortText={withShortText} withWhiteSkin={withWhiteSkin} isOpen={isOpen}>
        {options &&
          options.map((option) => {
            return (
              <OptionStyled key={`locale-option-${option.label}`}>
                <LinkStyled
                  withWhiteSkin={withWhiteSkin}
                  text={getLanguageNativeNames(option.label, withShortText)}
                  url={option.url}
                  onPress={() => setIsOpen(false)}
                />
              </OptionStyled>
            );
          })}
      </OptionsStyled>
    </Wrapper>
  );
};
